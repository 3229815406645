import { gsap, ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)

export default class PhotoAlbum {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.image = [...this.DOM.el.querySelectorAll('.photo--image')]
    this.create()
  }

  create() {
    this.init()
  }

  clearStyles(that) {
    that.DOM.image.forEach((element) => {
      gsap.set(element, { clearProps: 'all' })
    })
  }

  init() {
    const that = this

    ScrollTrigger.matchMedia({
      // Desktop
      '(min-width: 992px)': function () {
        that.clearStyles(that)

        gsap.set(that.DOM.image[0], { rotate: -15, xPercent: 100, scale: 1.2 })
        gsap.set(that.DOM.image[1], { rotate: -20, scale: 1.2 })
        gsap.set(that.DOM.image[2], { rotate: 2, xPercent: -100, scale: 1.2 })

        gsap.to([that.DOM.image], {
          scrollTrigger: {
            trigger: that.DOM.el,
            start: 'bottom bottom',
            repeatRefresh: true
            //   markers: true
          },
          rotate: 0,
          xPercent: 0,
          ease: 'expo.out',
          scale: 1,
          duration: 1.2
        })
      },

      // Mobile
      '(max-width: 991px)': function () {
        // No animation needed
        that.clearStyles(that)
      }
    })
  }

  onResize() {
    this.init()
  }
}
