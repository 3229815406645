import Highway from '@dogstudio/highway'
import gsap from 'gsap'

// to: The data-router-view to display.
// from: The data-router-view to hide.
// done: The required callback method that has to be called once the animation is done.
// trigger: The triggered link, popstate or script.

class Transition extends Highway.Transition {
  in({ from, to, done }) {
    const timeline = gsap.timeline({
      defaults: {
        // duration: 1,
        ease: 'expo.inOut'
      },
      onStart: () => {
        to.style.position = 'absolute'
        to.style.zIndex = 8
        to.style.top = 0
        to.style.left = 0

        from.style.position = 'relative'
        from.style.zIndex = 2
      },
      onComplete: () => {
        // Set New View in DOM Stream
        to.style.position = 'static'

        // Remove Old View
        from.remove()

        done()
      }
    })

    timeline.fromTo(
      to,
      2.5,
      { y: `${window.innerHeight}` },
      {
        y: 0
        //  ease: 'power2.inOut'
      },
      0
    )

    timeline.fromTo(
      from,
      2.5,
      { y: 0 },
      {
        y: `-${window.innerHeight}`
      },
      0.125
    )
  }

  out({ done }) {
    done()
  }
}

export default Transition
