import Highway from '@dogstudio/highway'

class Renderer extends Highway.Renderer {
  constructor(props) {
    // pass properties into constructor of parent class
    super(props)
  }

  // onEnter: This method is run when the data-router-view is added to the DOM Tree.
  onEnter(props) {
    // H.emit('ENTER');
    //  console.log('onEnter', props)
  }

  // onEnterCompleted: This method is run when the transition to display the data-router-view is done.
  onEnterCompleted() {
    // console.log('Renderer.onEnterCompleted')
    // console.log(document.querySelector('iframe'))
    // H.emit('ENTER_COMPLETE');
    // console.log('onEnterComplete')
  }
  // onLeave: This method is run when the transition to hide the data-router-view runs.
  onLeave() {
    //  console.log('Renderer.onLeave')
    // H.emit('LEAVE');
    // console.log('onLeave')
  }

  // onLeaveCompleted: This method is run when the data-router-view is removed from the DOM Tree.
  onLeaveCompleted() {
    //  console.log('Renderer.onLeaveCompleted')
    // H.emit('LEAVE_COMPLETE');
  }
}

export default Renderer
