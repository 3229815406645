export default class Testimonials {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.button = this.DOM.el.querySelector('.js-button')
    this.DOM.holder = this.DOM.el.querySelector('.js-holder')
    this.DOM.items = [...this.DOM.el.querySelectorAll('.testimonial__slide')]
    this.numberOfItems = parseInt(this.DOM.el.dataset.number)
    this.offset = this.numberOfItems * 2
    this.allReviews = []

    this.init()
  }

  init() {
    // this.swiperInit()
    this.addEventListeners()
    this.fetchReviews()
  }

  async fetchReviews() {
    // grabs all posts NOT THE FIRST 4
    const response = await fetch('/wp-json/wp/v2/testimonials?per_page=100&acf_format=standard')
    const data = await response.json()

    // get acf data for each post
    data.forEach((item) => {
      const obj = {
        name: item.acf.name,
        quote: item.acf.quote
      }

      this.allReviews.push(obj)
    })
  }

  getNumberOfReviews() {
    // first click load 6 more

    // disable button if offset is greater than length of all reviews
    if (this.offset > this.allReviews.length) {
      this.DOM.button.disabled = true
      return
    }

    let currentReviews = this.allReviews.slice(this.numberOfItems, this.offset)

    currentReviews.forEach((post) => {
      this.showReviews(post)
    })
  }

  showReviews(post) {
    const postHTML = this.postReview(post)
    const newPost = new DOMParser().parseFromString(postHTML, 'text/html').body.firstChild
    this.DOM.holder.appendChild(newPost)

    this.scroll.update()
  }

  postReview(post) {
    return `
      <div class="testimonial__note">
        <div class="testimonial__note__inner">
            <div class="ts__quote-group">
            <p class="testimonial__quote">${post.quote}</p>
        </div>
          <h4 class="ts__name">${post.name}</h4>
        </div>
      </div>
    `
  }

  onButtonClick() {
    this.getNumberOfReviews()

    // on click add offset by number of items
    this.offset = this.numberOfItems + this.offset
  }

  addEventListeners() {
    this.clickEvent = this.onButtonClick.bind(this)

    this.DOM.button.addEventListener('click', this.clickEvent)
  }
}
