import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import Header from './Components/Header'
import Preloader from './Components/Preloader'
import Modal from './Components/Modal'
import Cursor from './Components/cursor'
import CookiePolicy from './Components/CookiePolicy'
import Scroll from './Components/Scroll'

//Classes
import BlockManager from './Classes/BlockManager'
import HeroManager from './Classes/HeroManager'
import Detection from './Classes/Detection'

// Page Transitions
import HighwayPageTranstions from './Transitions'

// Utils
// import { getMousePos } from './Utils'

//Pages
import Locations from './Pages/locations'

class App {
  constructor() {
    // this.gutenberg = wp.blockEditor
    if (typeof wp != 'undefined') {
      // variable is undefined or null
      // Checking if we are viewing on backend or not
      if (wp.blockEditor) {
        this.addEventListeners()
      } else {
        this.init()
      }
    } else {
      this.init()
    }
  }

  init() {
    this.consoleMessage()
    this.detection()
    this.createContent()
    this.createHero()
    this.createSettings()
    this.createBlocks()
    this.addEventListeners()
    this.pageTransitions()
    this.onResize()
  }

  pageTransitions() {
    this.activatePageTransitions = document.querySelector('.transitions_was_yes')
    if (this.activatePageTransitions) {
      this.Highway = new HighwayPageTranstions({
        scroll: this.smoothScroll,
        body: this.body,
        blockManager: this.BlockManager,
        heroManager: this.HeroManager,
        cursor: this.cursor,
        header: this.header,
        locations: Locations
      })
    }
  }

  consoleMessage() {
    console.groupCollapsed(
      '%cThis website was built by Maxwell Kirwin',
      'color:white, background:black'
    )
    console.log('Need a new website? Find out more | https://maxwellkirwin.co.uk')
    console.groupEnd()
  }

  createSettings() {
    this.preloaderInView = document.querySelector('.pre_loader')
    this.modalInView = document.querySelector('#myModal')
    this.cursorOnPage = document.querySelector('.cursor')
    this.devTools = document.querySelector('.dev-tools')
    this.gdpr = document.querySelector('#gdpr-box')

    if (this.preloaderInView) {
      this.preloader = new Preloader()
      //   console.log(this.preloader._events)
      this.preloader.once('completed', this.onPreloaded.bind(this))
    } else {
      if (this.HeroManager.theHero) {
        this.HeroManager.theHero.onLoad()
      }
      if (this.gdpr) {
        this.cookiePolicy = new CookiePolicy()
      }
    }

    if (this.modalInView) {
      this.modal = new Modal()
    }
    if (this.cursorOnPage) {
      // Initialize custom cursor
      this.mouse = { x: 0, y: 0 }
      this.cursor = new Cursor({ mouse: this.mouse })
    }
  }

  createContent() {
    this.scrollWrapper = document.querySelector('[data-scroll-container]')
    this.smoothScroll = new Scroll({ container: this.scrollWrapper })
    this.scrolling = document.querySelector('.header__activate_scrolling')
    this.header = new Header({
      scroll: this.smoothScroll,
      container: this.scrollWrapper
    })
    this.body = document.querySelector('body')
  }

  onPreloaded() {
    //  console.log('run on preloadered')
    this.preloader.destroy()
    // run hero animations

    if (this.gdpr) {
      this.cookiePolicy = new CookiePolicy()
    }

    // check if there is a hero on the page
    if (this.HeroManager.theHero) {
      this.HeroManager.theHero.onLoad()
    }
  }

  checkforGutenberg() {
    const hero = document.querySelector('[data-hero]')
    //  const block = document.querySelector('[data-block]')
    if (hero) {
      /*post-title-0 is ID of Post Title Textarea*/
      //Actual functions goes here
      this.blocksLoaded = true
      this.createHero()
      this.createBlocks()
    }
    if (this.blocksLoaded) {
      clearInterval(this.loadingTimer)
    }
  }

  runGutenberg() {
    this.consoleMessage()
    console.log('this is the backend')
    // check for heros  & run them
    this.blocksLoaded = false
    this.loadingTimer = setInterval(this.checkforGutenberg.bind(this), 500)
  }

  createHero() {
    this.currentHero = document.querySelector('[data-hero]')
    this.preloaderInView = document.querySelector('.pre_loader')
    this.HeroManager = new HeroManager({
      hero: this.currentHero,
      header: this.header,
      screenSize: this.screenSize,
      preloader: this.preloaderInView
    })
  }

  createBlocks() {
    this.blocks = [...document.querySelectorAll('[data-block]')]
    this.blocksOnPage = []

    // Check this if you want to create a new JS Block
    this.BlockManager = new BlockManager({
      blocks: this.blocks,
      scroll: this.smoothScroll,
      wrapper: this.scrollWrapper,
      screenSize: this.screenSize
    })

    // if no blocks on the page, run createPages
    // if (!this.blocksOnPage.length) {
    // if (!this.blocks.length) {
    //   this.createPages()
    // }

    this.createPages()
  }
  createPages() {
    this.pages = {}
    // add a if statement for each page
    if (this.body.classList.contains('single-locations')) {
      this.pages.locations = new Locations({
        id: this.body,
        scroll: this.smoothScroll,
        container: this.scrollWrapper,
        header: this.header
      })
    } else {
      //  'no pages here'
    }
  }

  detection() {
    this.screenSize = ''
    this.detection = new Detection(this.screenSize)
    this.screenSize = this.detection.screenSize
  }

  onResize() {
    // re calculate screen size
    this.detection.init()
    this.screenSize = this.detection.screenSize

    // if (this.mobile) {
    //     console.log('mobile')
    //     this.ImageSection = new ImageSection.widthChange()
    // }

    // when we have smooth scroll enabled
    ScrollTrigger.addEventListener('refresh', () => this.smoothScroll.update())

    if (this.HeroManager.theHero) {
      this.HeroManager.theHero.onResize()
    }

    // run on Resize for all blocks
    if (this.BlockManager) {
      this.BlockManager.onResize(this.screenSize)
    }
  }

  addEventListeners() {
    // check for gutenberg
    if (typeof wp != 'undefined') {
      if (wp.blockEditor) {
        // GUTENBERG
        window._wpLoadBlockEditor.then(this.runGutenberg.bind(this))
        return
      }
    }

    // if (this.scrolling) {
    //   window.addEventListener('scroll', this.header.scrolling.bind(this))
    // }

    this.smoothScroll.scroll.on('scroll', this.header.scrolling.bind(this))

    // check for frontend
    if (this.cursorOnPage) {
      // Mouse effects on all links and others
      ;[...document.querySelectorAll('a')].forEach((link) => {
        link.addEventListener('mouseenter', () => this.cursor.enter())
        link.addEventListener('mouseleave', () => this.cursor.leave())
      })
    }

    window.addEventListener('resize', this.onResize.bind(this))

    if (this.devTools) {
      const gridSelector = this.devTools.querySelector('#griddevtools')
      const darkmode = this.devTools.querySelector('#darkmodedevtools')
      const preloaderSelector = this.devTools.querySelector('#preloaderdevtools')

      const grid = document.querySelector('.dt-grid')
      gridSelector.addEventListener('change', (event) => {
        if (event.currentTarget.checked) {
          grid.classList.add('on')
        } else {
          grid.classList.remove('on')
        }
      })
      darkmode.addEventListener('change', (event) => {
        this.body.classList.toggle('dark-mode')
      })

      preloaderSelector.addEventListener('change', () => {
        preloaderSelector.checked ? this.preloader.loop() : this.preloader.hide()
      })
    }
  }
}

const website = new App()
