import imagesLoaded from 'imagesloaded'
import EventEmitter from '../Classes/EventEmitter'
import gsap from 'gsap'
import { GSDevTools, DrawSVGPlugin } from 'gsap/all'
gsap.registerPlugin(GSDevTools, DrawSVGPlugin)

export default class Preloader extends EventEmitter {
  constructor() {
    super()
    this.DOM = { el: document.querySelector('.pre_loader') }
    this.DOM.hidePage = document.querySelector('.hide-whole-page')
    this.DOM.images = [...document.querySelectorAll('img')]
    this.DOM.numberText = this.DOM.el.querySelector('.preloader__number__text')
    this.DOM.progressCircle = this.DOM.el.querySelector('circle')
    this.DOM.icon = this.DOM.el.querySelector('.centered_pre__loader')
    this.DOM.whiteOutline = this.DOM.icon.querySelector('.bayview-b-icon-white')
    this.DOM.blueOutline = this.DOM.icon.querySelector('.bayview-b-icon-blue')
    this.DOM.iconBirds = [...this.DOM.icon.querySelectorAll('.birds')]
    this.length = 0
    gsap.set([this.DOM.whiteOutline, this.DOM.blueOutline, this.DOM.progressCircle], {
      drawSVG: '0%'
    })
    gsap.set([this.DOM.iconBirds], { autoAlpha: 0, rotate: 15 })
    this.imageLoader()
  }

  imageLoader() {
    const imgLoad = imagesLoaded(this.DOM.images)
    imgLoad.on('progress', (instance, img) => {
      this.onImagesLoaded(instance, img)
    })
  }

  onImagesLoaded(instance, img) {
    this.loaderTimeline = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        onComplete: () => {
          this.DOM.hidePage.classList.add('hide')
          this.emit('completed', 'yes')
        }
      },
      delay: 0.5
    })

    this.loaderTimeline
      .to(this.DOM.numberText, {
        innerText: 100,
        duration: 3.5,
        snap: 'innerText'
      })
      .to(this.DOM.progressCircle, { drawSVG: '100%', ease: 'expo.out', duration: 3.5 }, 0)
      .to(
        [this.DOM.blueOutline, this.DOM.whiteOutline],
        { drawSVG: '100%', ease: 'expo.out', duration: 1.5, stagger: 0.5 },
        0.5
      )
      .to([this.DOM.iconBirds], { autoAlpha: 1, ease: 'expo.out', duration: 3, rotate: 0 }, 2)

    //   .from(this.DOM.B, 1, { drawSVG: 0 })

    // GSDevTools.create()

    //   console.log( 'this image is loaded', img)
    // this.length += 1
    // const percent = this.length / this.DOM.images.length
    // this.DOM.numberText.innerHTML = `${Math.round(percent * 100)}%`
    // if (percent === 1) {
    //   // after we have loaded all the images wait 2 seconds before removing preloader
    //   setTimeout(() => {
    //     this.emit('completed', 'yes')
    //   }, 3000)
    // }
  }

  destroy() {
    this.DOM.el.classList.add('hidden__preloader')
    setTimeout(() => {
      this.DOM.el.parentNode.removeChild(this.DOM.el)
    }, 2000)
  }

  loop() {
    this.DOM.el.classList.remove('hidden__preloader')

    this.loaderTimeline.restart()
  }

  hide() {
    this.loaderTimeline.pause()
    this.DOM.el.classList.add('hidden__preloader')
  }
}
