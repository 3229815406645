import { gsap, ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, EffectCards } from 'swiper'

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectCards])

export default class Testimonials {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.wrapper = this.DOM.el.querySelector('.ss-wrapper')
    this.DOM.slider = this.DOM.el.querySelector('.Slideshow-slider')
    this.DOM.scrollbar = this.DOM.el.querySelector('.swiper-scrollbar')
    this.DOM.items = [...this.DOM.el.querySelectorAll('.ss--item')]
    this.init()
  }

  swiperInit() {
    this.swiper = new Swiper(this.DOM.slider, {
      slidesPerView: 1,
      grabCursor: true,
      effect: 'cards',
      updateOnImagesReady: true,
      //  loop: true,
      cardsEffect: {
        // transformEl: ''
      }
    })
  }

  //  moveOnScroll() {
  //         // Scroll triggered movement
  //   const tl = new gsap.timeline();

  //   tl.set(this.DOM.slider, {willChange: "transform"});

  //   tl.fromTo(this.DOM.slider, {
  //       x: -300
  //   }, {
  //       x: 0,
  //       ease: "none"
  //   }, 0);

  //   // when you add multiple slideshows this should be selected for even index slideshows
  //   // tl.fromTo(carousel[1], {
  //   //     x: 300
  //   // }, {
  //   //     x: 0,
  //   //     ease: "none"
  //   // }, 0);

  //   tl.set(this.DOM.slider, {willChange: "auto"});

  //   ScrollTrigger.create({
  //       trigger: this.DOM.el,
  //       animation: tl,
  //       start: "top bottom",
  //       end: "bottom top",
  //       scrub: 0.3,
  //      // markers: true,
  //       refreshPriority: -14
  //   })

  //   }

  init() {
    this.swiperInit()
    // this.moveOnScroll()
  }
  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }
}
