// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

export default class HeroSlideshow {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.slider = this.DOM.el.querySelector('.hero__slideshow__container')
    this.DOM.slides = [...this.DOM.el.querySelectorAll('.hero__slideshow__slide')]
    this.DOM.pagination = this.DOM.el.querySelector('.hs__swiper-pagination')
    this.DOM.buttonNext = this.DOM.el.querySelector('hs__button-next')
    this.DOM.buttonPrev = this.DOM.el.querySelector('hs__button-prev')
    this.DOM.scrollBar = this.DOM.el.querySelector('hs__scrollbar')
  }
  create() {
    this.onResize()
  }
  init() {
    this.swiper()
  }
  swiper() {
    this.swiperSlider = new Swiper(this.DOM.slider, {
      spaceBetween: 30,
      effect: 'fade',
      simulateTouch: true,
      loop: true,
      pagination: {
        el: this.DOM.pagination,
        clickable: true
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: this.DOM.buttonNext,
        prevEl: this.DOM.buttonPrev
      },
      on: {
        init: function () {
          console.log('swiper initialized')
        }
      }
    })
  }

  onResize() {}

  onLoad() {
    this.swiper()
  }

  gutenberg() {
    if (window.acf) {
      this.swiper()
      //   window.acf.addAction('render_block_preview/type=hero-slideshow', this.swiper.bind(this))
    }
  }
}
