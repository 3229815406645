import { gsap, SplitText } from 'gsap/all'
gsap.registerPlugin(SplitText)

// This Variable splits a title into lines and fades it up

export const SplitTitle = (title) => {
  const oldTitle = title.querySelector('.lineChild')

  if (oldTitle) {
    const titles = title.querySelectorAll('.lineChild')

    titles.forEach((element) => {
      gsap.set(element, { clearProps: 'all' })
    })

    return titles
  }

  const newTitle = new SplitText(title, { type: 'lines', linesClass: 'lineChild' })
  const innerTitle = new SplitText(title, { type: 'lines', linesClass: 'lineParent' })

  return newTitle.lines
}
