import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap, ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)

export default class HeroFullWidth {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.subtitle = this.DOM.el.querySelector('.hfw-subtitle')
    this.DOM.title = this.DOM.el.querySelector('.hfw-title')
    this.DOM.image = this.DOM.el.querySelector('img')
    this.DOM.birds = [...this.DOM.el.querySelectorAll('svg .bird')]
    this.title = SplitTitle(this.DOM.title)
    this.subtitle = SplitTitle(this.DOM.subtitle)
    this.Timelines = {}
  }

  create() {
    //  console.log('hello again')

    // gsap.set([this.DOM.image], { clearProps: 'all' })

    // this.DOM.birds.forEach((element) => {
    //   gsap.set(element, { clearProps: 'all' })
    // })

    gsap.set(this.DOM.image, { scale: 1.2 })
    gsap.set([this.title, this.subtitle], { yPercent: 100 })
    gsap.set(this.DOM.birds, { autoAlpha: 0, yPercent: -40 })

    // split text
    this.onResize()
  }

  animate() {
    this.Timelines.in = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8,
        repeatRefresh: true
      }
    })

    this.Timelines.in
      .to(this.DOM.birds, {
        autoAlpha: 1,
        yPercent: 0,
        stagger: 0.2
      })
      .to(
        [this.title, this.subtitle],
        {
          yPercent: 0,
          duration: 0.8,
          stagger: '0.2'
        },
        0
      )
      // .to([this.DOM.desc, this.DOM.button], { autoAlpha: 1 })
      .to(
        this.DOM.image,
        {
          scale: 1,
          duration: 2,
          ease: 'power4.out',
          onComplete: (image) => {
            // console.log('Completed')
          }
        },
        0
      )
  }

  destroy() {
    this.Timelines.in.kill()
    this.Timelines.out.kill()
    this.Timelines.in = null
    this.Timelines.out = null
  }

  // animate text as we scroll out of hero
  onScrollOut() {
    this.Timelines.out = gsap.timeline({
      scrollTrigger: {
        trigger: this.DOM.el,
        ease: 'expo.out',
        repeatRefresh: true,
        // bottom of the scroller hits the bottom of the element + 10%
        start: 'bottom bottom-=10%',
        end: 'bottom bottom-=20%',
        toggleActions: 'play pause resume reset',
        // markers: true,
        scrub: 0.5
      }
    })

    this.Timelines.out
      .to([this.title, this.subtitle], { yPercent: -110 })
      .to(this.DOM.birds.reverse(), { yPercent: -100, stagger: 0.2, autoAlpha: 0 }, 0)
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.animate()
    this.onScrollOut()
  }
}
