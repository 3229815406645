import Highway from '@dogstudio/highway'
import Renderer from './renderer'
import Transition from './transition'
import Locations from '../Pages/Locations'

export default class HighwayPageTranstions {
  constructor({ scroll, body, blockManager, heroManager, cursor, header, locations }) {
    this.scroll = scroll
    this.body = body
    this.BlockManager = blockManager
    this.heroManager = heroManager
    this.cursor = cursor
    this.header = header
    this.links = document.querySelectorAll('header a')
    this.allLinks = document.querySelectorAll('a')
    this.adminBarLinks = document.querySelectorAll('#wpadminbar a')
    this.wiper = { el: document.querySelector('.wiper') }
    this.wiper.title = this.wiper.el.querySelector('.js-title span')
    this.pageCover = document.querySelector('.page-cover')
    this.locations = locations

    this.init()
  }

  create() {
    this.H = new Highway.Core({
      renderers: {
        default: Renderer
      },
      transitions: {
        default: Transition
      }
    })

    this.H.detach(this.adminBarLinks)

    // assign active on page load
    this.links.forEach((a) => {
      a.classList.toggle('active', a.pathname === this.H.location.pathname)
    })
  }

  init() {
    this.create()
    this.disableLinks()
    this.addEventListeners()
  }

  disableLinks() {
    // find all links with a href containing '/locations'
    const disableLinks = [...this.allLinks].filter((link) => link.href.includes('/locations/flat'))

    disableLinks.forEach((link) => {
      link.setAttribute('data-router-disabled', '')
      console.log(link)
      // set a data attribute 'data-router-disabled' on the link
    })
  }

  addEventListeners() {
    this.H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
      // this.pageCover.classList.add('js-active')
      // this.disableLinks()

      this.scroll.scroll.scrollTo(0, {
        disableLerp: false,
        duration: 100,
        easing: [0.42, 0.0, 1.0, 1.0],
        callback: () => {
          //this.pageCover.classList.remove('js-active')
        }
      })

      //   console.log('NAVIGATE_IN')
      this.heroManager.setupHero(to)

      this.wiper.title.textContent = to.view.dataset.title
    })

    this.H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
      //   console.log('NAVIGATE_OUT')
      this.BlockManager.deleteBlocks()
      this.heroManager.deleteHero()
      this.scroll.destroy()

      //close header
      if (this.header.DOM.toggleIcon.classList.contains('toggle_on')) {
        this.header.closeMenu()
      }

      //
      this.links.forEach((a) => {
        a.classList.toggle('active', a.pathname === location.pathname)
      })
    })

    this.H.on('NAVIGATE_END', ({ from, to, trigger, location }) => {
      //   console.log('NAVIGATE_END')
      this.heroManager.runHeroAnimation()
      this.BlockManager.enterPageTransition()

      this.disableLinks()

      if (to.page.body.classList.contains('single-locations')) {
        // console.log(locations)
        new Locations({
          id: this.body,
          scroll: this.scroll,
          container: this.scrollWrapper,
          header: this.header
        })
      }

      // Analytics
      if (typeof gtag !== 'undefined') {
        // eslint-disable-next-line
        gtag('config', 'UA-216575057-1', {
          page_path: location.pathname,
          page_title: to.page.title,
          page_location: location.href
        })
      }

      // reinit cursor enter and leave
      if (this.cursor) {
        // Mouse effects on all links and others
        ;[...document.querySelectorAll('a, button, .accordian-item')].forEach((link) => {
          link.addEventListener('mouseenter', () => this.cursor.enter())
          link.addEventListener('mouseleave', () => this.cursor.leave())
        })
      }

      // Query admin bar links, and new page's admin bar links
      const adminBarLinks = document.querySelectorAll('#wpadminbar a')
      const newAdminBarLinks = to.page.body.querySelectorAll('#wpadminbar a')

      // Replace every admin bar link's href value with new value
      for (let i = 0; i < newAdminBarLinks.length; i++) {
        adminBarLinks[i].href = newAdminBarLinks[i].href
      }

      // Detach admin bar links from Highway
      this.H.detach(adminBarLinks)
      this.scroll.reinit()
    })
  }
}
