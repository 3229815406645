export default class ImageSection {
  constructor({ screenSize, id }) {
    this.screenSize = screenSize
    this.DOM = { el: document.getElementById(id) }
    this.DOM.images = [...document.querySelectorAll('.image-sections__js')]
    this.init()
  }

  // run on resize
  onResize(screensized) {
    // Check if we are running on page load or on screen resize
    let screen
    screensized ? (screen = screensized) : (screen = this.screenSize)

    if (screen === 'mobile' || screen === 'tablet') {
      this.DOM.images.forEach((imageGroup) => {
        // if image__item is the first child in the div, add class so on mobile view image is shown at the top
        if (imageGroup.children[0].classList.contains('image__item')) {
          imageGroup.classList.add('column-reverse')
        }
      })
    } else {
      this.DOM.images.forEach((imageGroup) => {
        // if image__item is the first child in the div, add class so on mobile view image is shown at the top
        if (imageGroup.children[0].classList.contains('image__item')) {
          imageGroup.classList.remove('column-reverse')
        }
      })
    }
  }

  init() {
    //  console.log(this)
    // run on load
    this.onResize(this.screenSize)
  }
}
