import { gsap, SplitText } from 'gsap/all'
gsap.registerPlugin(SplitText)

import { TextReveal } from '../Animations/textReveal'

export default class Header {
  constructor({ scroll }) {
    this.DOM = { el: document.querySelector('header') }
    this.DOM.headerType = this.DOM.el.querySelector('.header-type')
    this.DOM.toggleIcon = this.DOM.el.querySelector('.menu-toggle-circle')
    this.DOM.mobileMenu = this.DOM.el.querySelector('.mobile-sidebar')
    this.DOM.pageBackground = document.querySelector('.page-cover')
    this.DOM.fullScreenNav = this.DOM.el.querySelector('.fullscreen-nav-js')
    this.DOM.revealActive = this.DOM.el.classList.contains('header__activate_scrolling')
    this.DOM.menuItem = [...document.querySelectorAll('.menu-item-has-children a:not(.sub-menu a)')]
    this.DOM.navItems = [...document.querySelectorAll('.menu-item a:not(.sub-menu a, footer a)')]
    this.DOM.menuItemHasChildren = [...document.querySelectorAll('.menu-item-has-children')]
    this.DOM.subMenus = [...document.querySelectorAll('.sub-menu')]
    this.screenLarge = window.matchMedia('(min-width: 992px)')
    this.scrollUp = 'js-scroll-up'
    this.scrollDown = 'js-scroll-down'
    this.lastScroll = 0
    this.windowX = null
    this.winY = null
    this.scroll = scroll
    this.DOM.menuOpenText = this.DOM.el.querySelector('.mtd-open')
    this.DOM.menuCloseText = this.DOM.el.querySelector('.mtd-close')

    this.init()
  }

  toggleSubMenu(event) {
    const icon = event.currentTarget
    event.preventDefault()

    const currentSubMenu = icon.parentNode.querySelector('.sub-menu')

    if (this.DOM.headerType.classList.contains('logo_left')) {
      // Logo Left
    } else if (this.DOM.headerType.classList.contains('logo_middle')) {
      if (Array.isArray(this.DOM.subMenus)) {
        // close all other subMenus when selecting a new submenu
        this.DOM.subMenus.forEach((menu) => {
          menu.classList.remove('active-menu')
        })

        this.closeDropdowns()

        // on fullscreen with multiple dropdowns choose selected dropdowns
        if (icon.classList.contains('open-menu')) {
          icon.classList.remove('open-menu')
        } else {
          icon.classList.add('open-menu')
        }
      }
    } else {
    }

    //  console.log(icon)

    //show or hide each menu
    if (icon.classList.contains('js-open')) {
      currentSubMenu.classList.remove('active-menu')
      icon.classList.remove('js-open')
      //   console.log('x')

      // on first click fullscreen nav menu with multiple submenus
    } else if (icon.classList.contains('open-menu') && !icon.classList.contains('js-open')) {
      currentSubMenu.classList.add('active-menu')
      icon.classList.add('js-open')
      // console.log('g')
    } else if (
      !icon.classList.contains('js-open') &&
      this.DOM.headerType.classList.contains('logo_middle') &&
      Array.isArray(this.DOM.subMenus)
    ) {
      currentSubMenu.classList.remove('active-menu')
      icon.classList.remove('js-open')
      // console.log('z')
    } else {
      currentSubMenu.classList.add('active-menu')
      icon.classList.add('js-open')
      // console.log('gvvcc')
    }
  }

  setupTimelines() {
    // change title from 'MENU' to 'CLOSE'
    this.menuTextTL = gsap.timeline({
      paused: true,
      defaults: {
        ease: 'power2'
      }
    })
    this.menuTextTL
      .to(this.DOM.menuOpenText, { duration: 0.8, yPercent: -100 }, 0)
      .to(this.DOM.menuCloseText, { duration: 0.8, yPercent: -100 }, 0)
      .reverse()
  }

  init() {
    this.setupTimelines()
    this.splitNavItems()
    this.createDropdownIcon()
    this.eventListeners()
  }

  createDropdownIcon() {
    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItem.forEach((item) => {
          // create a plus span
          const plus = `<button class='sm-dropdown'></button>`
          item.insertAdjacentHTML('afterend', plus)
        })
      } else {
        // a single element
        // create a plus span
        const plus = `<button class='sm-dropdown'></button>`
        this.DOM.menuItem.insertAdjacentHTML('afterend', plus)
      }
    }
  }

  removeCover() {
    if (this.screenLarge.matches) {
      this.DOM.pageBackground.classList.remove('page-cover-opacity')
    }
  }

  hoverMenuOut() {
    const firstLine = [...this.querySelectorAll('.menu-split-line .menu-char-split')]
    const secondLine = [...this.querySelectorAll('.menu-split-clone .menu-char-split')]

    let timeline = gsap.timeline()

    timeline
      .to(
        firstLine,
        {
          yPercent: 0,
          stagger: function (index, target, targets) {
            //function-based value
            return 0.05 * index
          },
          duration: 0.5,
          ease: 'expo.out',
          transformOrigin: 'bottom',
          rotationX: 0
        },
        0
      )
      .to(
        secondLine,
        {
          yPercent: 0,
          stagger: function (index, target, targets) {
            //function-based value
            return 0.05 * index
          },
          duration: 0.5,
          ease: 'expo.out',
          transformOrigin: 'bottom',
          rotationX: 0
        },
        0
      )
  }

  hoverMenuIn() {
    const firstLine = [...this.querySelectorAll('.menu-split-line .menu-char-split')]
    const secondLine = [...this.querySelectorAll('.menu-split-clone .menu-char-split')]

    let timeline = gsap.timeline()

    timeline
      .to(
        firstLine,
        {
          yPercent: -100,
          stagger: function (index, target, targets) {
            //function-based value
            return 0.05 * index
          },
          duration: 0.4,
          ease: 'expo.out',
          transformOrigin: 'bottom',
          rotationX: -90
        },
        0
      )
      .to(
        secondLine,
        {
          yPercent: -100,
          stagger: function (index, target, targets) {
            //function-based value
            return 0.05 * index
          },
          duration: 0.4,
          ease: 'expo.out',
          transformOrigin: 'bottom',
          rotationX: 0
        },
        0
      )
  }

  splitNavItems() {
    this.DOM.navItems.forEach((item, i) => {
      let slp = new SplitText(item, {
        type: 'lines, chars',
        linesClass: 'menu-split-line',
        charsClass: 'menu-char-split'
      })

      let cloneDiv = item.children[0].cloneNode(true)

      cloneDiv.classList.remove('menu-split-line')
      cloneDiv.classList.add('menu-split-clone')

      item.appendChild(cloneDiv)
    })

    this.textReveal = new TextReveal([...this.DOM.navItems])
  }

  eventListeners() {
    this.DOM.navItems.forEach((item) => {
      item.addEventListener('click', (event) => {
        if (event.currentTarget.classList.contains('active')) return this.closeMenu()
      })
    })

    if (this.DOM.toggleIcon) {
      this.DOM.toggleIcon.addEventListener('click', this.toggleMenu.bind(this))
    }
    window.addEventListener('resize', this.removeCover.bind(this))

    this.DOM.navItems.map((item, i) => {
      item.addEventListener('mouseenter', this.hoverMenuIn)
      item.addEventListener('mouseleave', this.hoverMenuOut)
    })

    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItemHasChildren.forEach((element) => {
          const icon = element.querySelector('.sm-dropdown')
          if (icon) {
            icon.addEventListener('click', this.toggleSubMenu.bind(this))
          }
        })
      } else {
        // a single element
        this.DOM.menuItemHasChildren
          .querySelector('.sm-dropdown')
          .addEventListener('click', this.toggleSubMenu.bind(this))
      }
    }
  }

  scrolling(obj) {
    // console.log(obj)
  }

  // triggered from index.js transitions
  closeMenu() {
    // console.log(this)
    this.DOM.toggleIcon.classList.remove('toggle_on')

    // reverse timeline
    this.menuTextTL.reversed(!this.menuTextTL.reversed())

    this.textReveal.in()
    this.closeDropdowns()
    this.DOM.fullScreenNav.classList.remove('opacity-full')
    document.querySelector('body').style.overflow = 'visible'
  }

  toggleMenu() {
    this.DOM.toggleIcon.classList.toggle('toggle_on')

    if (this.DOM.toggleIcon.classList.contains('toggle_on')) {
      this.textReveal.in()
    } else {
      // hide texts
      this.textReveal.out()
    }

    // reverse timeline
    this.menuTextTL.reversed(!this.menuTextTL.reversed())

    // IF we have logo - left header selected
    if (this.DOM.mobileMenu) {
      this.DOM.mobileMenu.classList.toggle('mobile-menu-appear')
      this.DOM.pageBackground.classList.toggle('page-cover-opacity')
      //   this.DOM.page.classList.toggle('paged');
    }

    if (this.DOM.headerType.classList.contains('logo_left')) return
    // IF we have logo middle selected
    // This will work if scrolling header is remvoed otherwise please check below in scorlling function
    if (this.DOM.fullScreenNav.classList.contains('opacity-full')) {
      this.DOM.fullScreenNav.classList.remove('opacity-full')
      // Remove listener to re-enable scroll
      document.querySelector('body').style.overflow = 'visible'
    } else {
      this.DOM.fullScreenNav.classList.add('opacity-full')
      // disable scroll when fullscreen nav is visible
      document.querySelector('body').style.overflow = 'hidden'
    }
  }

  closeDropdowns() {
    const dropdwns = [...document.querySelectorAll('.sm-dropdown')]
    dropdwns.forEach((toggle) => toggle.classList.remove('js-open'))
  }
}
