export default class Gallery {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.galleryInner = this.DOM.el.querySelector('.js-gallery')
    this.DOM.images = [...this.DOM.el.querySelectorAll('img')]
    this.DOM.modal = { el: document.querySelector('.gallery-modal') }
    this.DOM.modal.currentImageNumber = this.DOM.modal.el.querySelector('.js-current-number')
    this.DOM.modal.totalImageNumber = this.DOM.modal.el.querySelector('.js-total-number')
    this.DOM.modal.exit = this.DOM.modal.el.querySelector('.js-exit')
    this.DOM.prevButton = this.DOM.modal.el.querySelector('.prev')
    this.DOM.nextButton = this.DOM.modal.el.querySelector('.next')
    this.DOM.modalImg = this.DOM.modal.el.querySelector('img')
    this.DOM.modalTitle = this.DOM.modal.el.querySelector('h2')
    this.DOM.modalP = this.DOM.modal.el.querySelector('figure p')
    this.DOM.currentImage

    // storing references to event listeners
    // so we can remove them later on

    this.showNextImageEvent = this.showNextImage.bind(this)
    this.showPrevImageEvent = this.showPrevImage.bind(this)
    this.handleClickOutsideEvent = this.handleClickOutside.bind(this)

    this.init()
  }

  showImage(element) {
    const el = element.currentTarget || element

    // UPDATE CURRENT NUMBER
    const index = this.DOM.images.indexOf(el)
    this.DOM.modal.currentImageNumber.textContent = index + 1

    if (!el) {
      //  console.info('no image to show');
      return
    }

    this.DOM.modalImg.src = el.src
    this.DOM.modalTitle.textContent = el.title
    this.DOM.modalP.textContent = el.dataset.description
    this.currentImage = el
    this.openModal()
  }

  openModal() {
    // console.info('Opening Modal...');
    // First check if the modal is already open
    if (this.DOM.modal.el.matches('.open')) {
      //  console.info('Modal already open');
      return // stop the function from running
    }
    this.DOM.modal.el.classList.add('open')
    // this.scroll.scroll.stop()

    // Event listeners to be bound when we open the modal:
    window.addEventListener('keyup', this.keyupEvent)
    this.DOM.nextButton.addEventListener('click', this.showNextImageEvent)
    this.DOM.prevButton.addEventListener('click', this.showPrevImageEvent)
  }

  closeModal() {
    this.DOM.modal.el.classList.remove('open')
    this.scroll.scroll.start()
    // TODO: add event listeners for clicks and keyboard..
    window.removeEventListener('keyup', this.keyupEvent)
    this.DOM.nextButton.removeEventListener('click', this.showNextImageEvent)
    this.DOM.prevButton.removeEventListener('click', this.showPrevImageEvent)
  }

  handleClickOutside(e) {
    if (e.target === e.currentTarget) {
      this.closeModal()
    }
  }

  handleKeyUp(event) {
    if (event.key === 'Escape') return this.closeModal()
    if (event.key === 'ArrowRight') return this.showNextImage()
    if (event.key === 'ArrowLeft') return this.showPrevImage()
  }

  showNextImage() {
    this.showImage(this.currentImage.nextElementSibling || this.DOM.galleryInner.firstElementChild)
  }
  showPrevImage() {
    this.showImage(
      this.currentImage.previousElementSibling || this.DOM.galleryInner.lastElementChild
    )
  }

  addEventListener() {
    this.DOM.images.forEach((image) => {
      image.addEventListener('click', this.showImage.bind(this))

      image.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          this.showImage.bind(this)
        }
      })
    })

    this.DOM.modal.el.addEventListener('click', this.handleClickOutside.bind(this))
    this.DOM.modal.exit.addEventListener('click', this.closeModal.bind(this))
  }

  init() {
    this.addEventListener()

    this.DOM.modal.totalImageNumber.textContent = this.DOM.images.length
  }
}
