export default class Accordion {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.items = [
      ...this.DOM.el.querySelectorAll('.accordion__item__master')
    ]

    this.init()
  }

  showList(event) {
    const item = event.target.closest('.accordion__item__master')
    const desc = item.querySelector('.list__item_desc')
    const icon = item.querySelector('.list__item_title .list__item_span')

    //get a perfect slide down
    if (desc.offsetHeight > 0) {
      // console.log('scrollUp')
      desc.style.height = `0px`
      // toggle between down arrow and straight bullet point
      icon.children[0].classList.remove('js_list__left')
      icon.children[1].classList.remove('js_list__right')
    } else {
      // console.log('scrollDown')
      desc.style.height = `${desc.scrollHeight}px`
      // toggle between down arrow and straight bullet point
      icon.children[0].classList.add('js_list__left')
      icon.children[1].classList.add('js_list__right')
    }
  }
  onResize() {}
  addEventListeners() {
    this.DOM.items.forEach((item) => {
      item.addEventListener('click', this.showList.bind(this))
    })
  }
  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }
  init() {
    this.addEventListeners()
    this.onResize()
  }
}
