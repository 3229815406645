export default class Locations {
  constructor({ id, scroll, container, header }) {
    this.DOM = { el: id }
    this.scroll = scroll
    this.container = container
    this.header = header
    this.DOM.button = this.DOM.el.querySelector('.js-button')
    this.DOM.bookingSection = this.DOM.el.querySelector('.js-book-now')
    this.DOM.embedOldLocation = this.DOM.el.querySelector('.js-embed')
    this.DOM.embed = this.DOM.embedOldLocation.innerHTML
    this.DOM.embedNewLocation = this.DOM.el.querySelector('.js-embed-new')

    //js gallery
    this.DOM.galleryInner = this.DOM.el.querySelector('.js-gallery')
    this.DOM.images = [...this.DOM.el.querySelectorAll('.js-gallery-item')]
    this.DOM.modal = { el: document.querySelector('.gallery-modal') }
    this.DOM.modal.currentImageNumber = this.DOM.modal.el.querySelector('.js-current-number')
    this.DOM.modal.totalImageNumber = this.DOM.modal.el.querySelector('.js-total-number')
    this.DOM.modal.exit = this.DOM.modal.el.querySelector('.js-exit')
    this.DOM.prevButton = this.DOM.modal.el.querySelector('.prev')
    this.DOM.nextButton = this.DOM.modal.el.querySelector('.next')
    this.DOM.modalImg = this.DOM.modal.el.querySelector('img')
    this.DOM.modalTitle = this.DOM.modal.el.querySelector('h2')
    this.DOM.modalP = this.DOM.modal.el.querySelector('figure p')
    this.DOM.currentImage
    this.showNextImageEvent = this.showNextImage.bind(this)
    this.showPrevImageEvent = this.showPrevImage.bind(this)

    // Accordion
    this.DOM.accordions = [...this.DOM.el.querySelectorAll('.accordion__item__master')]

    console.log('rerun')
    this.init()
    this.addEventlisteners()
  }

  showList(event) {
    const item = event.target.closest('.accordion__item__master')
    const desc = item.querySelector('.list__item_desc')
    const icon = item.querySelector('.list__item_title .list__item_span')

    //get a perfect slide down
    if (desc.offsetHeight > 0) {
      // console.log('scrollUp')
      desc.style.height = `0px`
      // toggle between down arrow and straight bullet point
      icon.children[0].classList.remove('js_list__left')
      icon.children[1].classList.remove('js_list__right')
    } else {
      // console.log('scrollDown')
      desc.style.height = `${desc.scrollHeight}px`
      // toggle between down arrow and straight bullet point
      icon.children[0].classList.add('js_list__left')
      icon.children[1].classList.add('js_list__right')
    }
  }

  buttonScroll(e) {
    e.preventDefault()

    // scroll down section
    this.scroll.scrollTo(this.DOM.bookingSection)
  }

  showImage(element) {
    const el = element.currentTarget || element

    console.log(el, this.DOM.modalImg)

    // UPDATE CURRENT NUMBER
    const index = this.DOM.images.indexOf(el)
    this.DOM.modal.currentImageNumber.textContent = index + 1

    if (!el) {
      //  console.info('no image to show');
      return
    }

    this.DOM.modalImg.src = el.src
    this.DOM.modalTitle.textContent = el.title
    this.DOM.modalP.textContent = el.dataset.description
    this.currentImage = el
    this.openModal()
  }

  openModal() {
    // console.info('Opening Modal...');
    // First check if the modal is already open
    if (this.DOM.modal.el.matches('.open')) {
      //  console.info('Modal already open');
      return // stop the function from running
    }
    this.DOM.modal.el.classList.add('open')
    // this.scroll.scroll.stop()

    // Event listeners to be bound when we open the modal:
    window.addEventListener('keyup', this.keyupEvent)

    this.DOM.nextButton.addEventListener('click', this.showNextImageEvent)
    this.DOM.prevButton.addEventListener('click', this.showPrevImageEvent)
  }

  closeModal() {
    this.DOM.modal.el.classList.remove('open')
    this.scroll.scroll.start()
    // TODO: add event listeners for clicks and keyboard..
    window.removeEventListener('keyup', this.keyupEvent)
    this.DOM.nextButton.removeEventListener('click', this.showNextImageEvent)
    this.DOM.prevButton.removeEventListener('click', this.showPrevImageEvent)
  }

  handleClickOutside(e) {
    if (e.target === e.currentTarget) {
      this.closeModal()
    }
  }

  handleKeyUp(event) {
    if (event.key === 'Escape') return this.closeModal()
    if (event.key === 'ArrowRight') return this.showNextImage()
    if (event.key === 'ArrowLeft') return this.showPrevImage()
  }

  showNextImage() {
    this.showImage(this.currentImage.nextElementSibling || this.DOM.galleryInner.firstElementChild)
  }
  showPrevImage() {
    this.showImage(
      this.currentImage.previousElementSibling || this.DOM.galleryInner.lastElementChild
    )
  }

  // no longer used
  enableWidget() {
    console.log('re-runnnn')
    // append widget
    const div = document.createElement('div')
    div.innerHTML = this.DOM.embed
    console.log(div, this.DOM.embedNewLocation)
    this.DOM.embedNewLocation.appendChild(div)
  }

  init() {
    this.DOM.modal.totalImageNumber.textContent = this.DOM.images.length
  }

  addEventlisteners() {
    this.clickEvent = this.buttonScroll.bind(this)
    this.accordionEvent = this.showList.bind(this)
    this.handleClickOutsideEvent = this.handleClickOutside.bind(this)
    this.closeModalEvent = this.closeModal.bind(this)

    this.DOM.button.addEventListener('click', this.clickEvent)

    this.DOM.accordions.forEach((item) => {
      item.addEventListener('click', this.accordionEvent)
    })

    //gallery

    this.DOM.images.forEach((image) => {
      image.addEventListener('click', this.showImage.bind(this))

      image.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          this.showImage.bind(this)
        }
      })
    })

    this.DOM.modal.el.addEventListener('click', this.handleClickOutsideEvent)
    this.DOM.modal.exit.addEventListener('click', this.closeModalEvent)
  }
}
